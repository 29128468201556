import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import LoanPrograms from "../components/Repeating/LoanProgramsGridRow";
// import HomeWorth from "../components/Repeating/HomeWorth";
import ValueProps from "../components/Repeating/ValueProps";
import FAQs from "../components/Repeating/FAQs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

import benefit1 from "../images/3.0 Services/Non QM Loans/Qualify without traditional income requirements.svg";
import benefit2 from "../images/3.0 Services/Non QM Loans/More relaxed credit requirements.svg";
import benefit3 from "../images/3.0 Services/Non QM Loans/Get a mortgage as a foreign national.svg";
import benefit4 from "../images/3.0 Services/Non QM Loans/You’re not limited by the number of financed properties you have.svg";

const Page = ({ data }) => {
  const faqs = [
    {
      question: "Why should I get a Non-QM mortgage?",
      answer: (
        <>
          <p>
            If you have inconsistent income, you aren’t a US citizen, or you
            have a lower credit score, a non-QM loan is usually your best
            option. That’s because it allows you to bypass the traditional
            qualification requirements.
          </p>
          <p>
            I am a foreign investor. Is this the right loan for me? Not being a
            US citizen doesn’t have to stop you from making an investment and
            owning land in the US. A non-QM loan is the most recommended way for
            a foreign investment.
          </p>
        </>
      ),
    },
    {
      question: "What is the downside to non-QM loans? ",
      answer: (
        <>
          <p>
            The downside is that you’ll usually need a larger down payment. But,
            on the flip side, you can qualify with a lower credit score and
            non-traditional income
          </p>
        </>
      ),
    },
    {
      question:
        "What type of assets can I use as proof to qualify for a Non-QM loan?",
      answer: (
        <>
          <p>
            While the exact requirements may vary, investment portfolio holdings
            and existing real estate equity are usually the most obvious choices
          </p>
        </>
      ),
    },
    {
      question: "I am a foreign investor. Is this the right loan for me?",
      answer: (
        <>
          <p>
            Not being a US citizen doesn’t have to stop you from making an
            investment and owning land in the US. A non-QM loan is the most
            recommended way for a foreign investment.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Non QM Loans Orange County | Nikkael Home Loans"
        description="Special circumstances may prohibit some from qualifying for a traditional mortgage. We can help get you set up with a Non QM Loan. Contact us today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 pt-10 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Non-QM Loans: When You Don’t Fit the Mold</h1>
              <p>
                There are special situations where you may not qualify for a
                standard or traditional mortgage—for example, you may have a
                non-traditional source of income or a low credit score. A Non-QM
                (non-qualified) mortgage allows you to get a loan based on other
                factors. If proving income is an issue, we can typically prove
                your income with your asset or bank statements.
              </p>
              <ButtonSolid href="/request-rates/" text="Request Rates" />
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/3.0 Services/Non QM Loans/1.0 Non QM Loans.jpg"
                loading="lazy"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-12 md:mb-16">
            <h2>The Advantages of a Non-QM Loan</h2>
          </header>

          <div className="grid gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="items-start md:flex md:space-x-8">
              <img src={benefit1} className="mb-3 flex-1 md:mb-0" />
              <div>
                <h3 className="heading-four mb-2">
                  Qualify without traditional income requirements
                </h3>
                <p className="mb-0">
                  You can use various assets you own to qualify instead of your
                  monthly income. For example, we can prove income by showing
                  regular deposits into an asset or bank account(s).
                </p>
              </div>
            </div>
            <div className="items-start md:flex md:space-x-8">
              <img src={benefit2} className="mb-3 flex-1 md:mb-0" />
              <div>
                <h3 className="heading-four mb-2">
                  More relaxed credit requirements
                </h3>
                <p className="mb-0">
                  Don’t let a low credit score hold you back—you can usually
                  qualify with a credit score under 600.
                </p>
              </div>
            </div>
            <div className="items-start md:flex md:space-x-8">
              <img src={benefit3} className="mb-3 flex-1 md:mb-0" />
              <div>
                <h3 className="heading-four mb-2">
                  Get a mortgage as a foreign national
                </h3>
                <p className="mb-0">
                  Without being a US citizen, this is your way to own a piece of
                  the American Dream.
                </p>
              </div>
            </div>
            <div className="items-start md:flex md:space-x-8">
              <img src={benefit4} className="mb-3 flex-1 md:mb-0" />
              <div>
                <h3 className="heading-four mb-2">
                  There’s no problem if you have another mortgage
                </h3>
                <p className="mb-0">
                  There’s no need to worry about other mortgages you may have
                  since they typically will not stop you from getting a new
                  Non-QM loan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/3.0 Services/eligibility.jpg"
                loading="lazy"
                width={560}
              />
            </div>
            <div>
              <h2>Do I Qualify for a Non-QM Mortgage?</h2>
              <p>
                Getting a Non-QM loan is best for those with special
                circumstances and those who may not qualify through traditional
                methods. As an example, Non-QM loans can work in the following
                situations:
              </p>
              <ul className="styled-list-checkmark mb-6">
                <li>You’re self-employed or have a non-traditional income</li>
                <li>You have a poor credit history</li>
                <li>You have limited documentation</li>
                <li>Your debt-to-income ratio is higher than 43%</li>
                <li>
                  You’re an investor who wants to purchase rental or vacation
                  properties
                </li>
              </ul>
              <ButtonSolid href="/request-rates/" text="Request Rates" />
            </div>
          </div>
        </div>
      </section>

      {/* <HomeWorth /> */}
      <FAQs heading="Common Questions About Non-QM Loans" uniqueFaqs={faqs} />
      <ValueProps />
      <Testimonials />
      <LoanPrograms
        heading="You Might Also Be Interested In"
        hidePrograms={[ 2, 5]}
      />
      <CallToAction
        heading="Interested in a Non-QM Loan?"
        text="We’re a top non-QM mortgage broker in Orange County, and we’re armed with answers to all your questions. Contact us today!"
      />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-non-qm.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-non-qm.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
